import React, { useEffect, useState, useRef } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { DebounceInput } from 'react-debounce-input'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import XLSX from 'xlsx'

import { GET, POST, PUT } from 'helpers/api'
import { formatMoney, findIdFromArray, findFromArrayById } from 'helpers/helpers'

import { FixedSizeList as List } from 'react-window'; 

function CreateUpdate() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxSettings = useSelector(state => state.settings)
  const reduxTransferData = useSelector(state => state.transferData)

  const scrollRef = useRef(null)

  const [categories, setCategories] = useState([]) // for instant create modal
  const [categoriesInitial, setCategoriesInitial] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [modalUpdateProduct, setModalUpdateProduct] = useState(false)

  const [poses, setPoses] = useState([])
  const [modalNotInventored, setModalNotInventored] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [barcode, setBarcode] = useState(false)
  const [search, setSearch] = useState('')
  const [searchDataExist, setSearchDataExist] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [annual, setAnnual] = useState(false)
  const [notInventoredProducts, setNotInventoredProducts] = useState([])
  const [data, setData] = useState({
    productList: [],
    posId: null,
  })

  async function inventoryCompleted(completed = false) {
    var sendData = { ...data }

    for (let i = 0; i < sendData.productList.length; i++) {
      if (sendData.productList[i]['actualBalance'] < 0 || sendData.productList[i]['actualBalance'] === "") {
        sendData.error = true
      }

      sendData.productList[i]['differenceAmount'] = (sendData.productList[i]['actualBalance'] - sendData.productList[i]['balance']) * sendData.productList[i]['price']
      sendData.productList[i]['divergence'] = sendData.productList[i]['actualBalance'] - sendData.productList[i]['balance']
    }

    if (sendData.error) {
      toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
      return
    }

    var response;
    if (id) {
      if (completed) {
        response = await PUT('/services/web/api/inventory-completed', sendData, { loader: true })
        if (response) {
          dispatch({ type: 'SET_INVENTORY_CREATE_DATA', payload: {} })
          navigate('/inventory')
        }
      } else {
        response = await PUT('/services/web/api/inventory', sendData, { loader: true })
        if (response) {
          dispatch({ type: 'SET_INVENTORY_CREATE_DATA', payload: {} })
          navigate('/inventory')
        }
      }
    } else {
      if (completed) {
        response = await PUT('/services/web/api/inventory-completed', sendData, { loader: true })
        if (response) {
          dispatch({ type: 'SET_INVENTORY_CREATE_DATA', payload: {} })
          navigate('/inventory')
        }
      } else {
        response = await POST('/services/web/api/inventory', sendData, { loader: true })
        if (response) {
          dispatch({ type: 'SET_INVENTORY_CREATE_DATA', payload: {} })
          navigate('/inventory')
        }
      }
    }
  }

  function handleProductListChange(e, index) {
    var dataCopy = { ...data }
    if (Number(e.target.value) > 999999999) {
      toast.error(t('EXCEEDS_MAXIMUM'))
      dataCopy.productList[index][e.target.name] = ""
      setData(dataCopy)
      return;
    }
    dataCopy.productList[index][e.target.name] = e.target.value
    setData(dataCopy)
  }

  function deleteProduct(index) {
    var dataCopy = { ...data }
    dataCopy.productList.splice(index, 1)
    setData(dataCopy)
  }

  async function searchProduct(value) {
    if (value.length === 0) {
      setSearchData([])
      setSearchDataExist(false)
    } else {
      const response = await GET("/services/web/api/product-inventory-list", { 'name': value, 'posId': data.posId, barcode: barcode }, { loader: true })

      if (response.length === 0) {
        setSearchDataExist(true)
        return
      }

      if (/^\d+$/.test(value) && response.length === 1) {
        setSearchData(response)
        setSearchDataExist(false)
        addToList(0, response, true)
        setSearch('')
      } else {
        setSearchData(response)
      }
    }
  }

  function addToList(index, arr = [], putCursor = false) {
    var searchDataCopy;
    if (arr.length > 0) {
      searchDataCopy = arr
    } else {
      searchDataCopy = [...searchData]
    }
    searchDataCopy[index].actualBalance = ''

    var dataCopy = { ...data }
    var productIndex = dataCopy.productList.findIndex(item => item.barcode === searchDataCopy[index]['barcode'])
    if (productIndex !== -1 && productIndex !== undefined) {
      toast.warning(t('DUPLICATE'))
    }
    dataCopy.productList.push(searchDataCopy[index])
    setData(dataCopy)
    setTimeout(() => {
      const byId = document.getElementById(dataCopy.productList.length)
      byId?.select()
      const lastChildElement = scrollRef.current?.lastElementChild;
      lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
    searchDataCopy.splice(index, 1)
    setSearchData(searchDataCopy)


    // scrollRef.current?.scrollIntoView({ behavior: "smooth" })
    if (searchDataCopy.length === 0) {
      setSearch('')
    }
  }

  async function notInventoredList() {
    console.log(data.productList);
    
    var arrayIds = []
    for (let i = 0; i < data.productList.length; i++) {
      arrayIds.push(data.productList[i]['balanceId'])
    }
    var prepareIds = { "balanceIds": [], "posId": data.posId }
    prepareIds.balanceIds = arrayIds

    const response = await POST(`/services/web/api/product-not-inventoried-list`, prepareIds, { loader: true })
    if (response) {
      var list = [...response.data]
      
      for (let i = 0; i < 10; i++) {
        list[i].actualBalance = list[i].balance
        list[i]['uniqueBalanceId'] = list[i]['balanceId'] + Math.floor(Math.random() * 999999);
        list[i].selected = false
      }
      setNotInventoredProducts(list)
      setModalNotInventored(true)
    }
  }

  function annualActualBalance() {
    var notInventoredProductsCopy = [...notInventoredProducts]
    if (annual) {
      var selectedExist = false

      for (let i = 0; i < notInventoredProductsCopy.length; i++) {
        if (notInventoredProductsCopy[i]['selected']) {
          selectedExist = true
          break;
        }
      }

      if (selectedExist) {
        for (let i = 0; i < notInventoredProductsCopy.length; i++) {
          if (notInventoredProductsCopy[i]['selected']) {
            notInventoredProductsCopy[i]['actualBalance'] = 0
          }
        }
      } else {
        for (let i = 0; i < notInventoredProductsCopy.length; i++) {
          notInventoredProductsCopy[i]['actualBalance'] = 0
        }
      }
    } else {
      for (let i = 0; i < notInventoredProductsCopy.length; i++) {
        notInventoredProductsCopy[i]['actualBalance'] = notInventoredProductsCopy[i]['balance']
      }
    }
    setNotInventoredProducts(notInventoredProductsCopy)
  }

  function setActualBalanceModal(e, index) {
    var notInventoredProductsCopy = [...notInventoredProducts]
    notInventoredProductsCopy[index]['actualBalance'] = e.target.value
    setNotInventoredProducts(notInventoredProductsCopy)
  }

  function addToListSelectedProducts() {
    var notInventoredProductsCopy = [...notInventoredProducts]
    var dataCopy = { ...data }

    for (let i = 0; i < notInventoredProductsCopy.length; i++) {
      if (notInventoredProductsCopy[i]['selected']) {
        dataCopy.productList.push(notInventoredProductsCopy[i])
      }
    }
    setData(dataCopy)
    setModalNotInventored(false)
  }

  function addToListMinusBalanceProducts() {
    var notInventoredProductsCopy = [...notInventoredProducts]
    var dataCopy = { ...data }

    var minusProducts = notInventoredProductsCopy.filter(element => element.balance < 0);
    dataCopy.productList = minusProducts
    setData(dataCopy)
    setModalNotInventored(false)
  }

  function addToListNotInventoredProduct(index) {
    var notInventoredProductsCopy = [...notInventoredProducts]
    var dataCopy = { ...data }

    dataCopy.productList.push(notInventoredProductsCopy[index])
    notInventoredProductsCopy.splice(index, 1)

    setNotInventoredProducts(notInventoredProductsCopy)

    if (notInventoredProductsCopy.length === 0) {
      setModalNotInventored(false)
    }
  }

  function addToListNotInventoredProducts() {
    var notInventoredProductsCopy = [...notInventoredProducts]
    var dataCopy = { ...data }

    dataCopy.productList = dataCopy.productList.concat(notInventoredProductsCopy)
    setData(dataCopy)
    //countTotalAmount()
    setModalNotInventored(false)
  }

  function markAsSelected(bool, index) {
    var notInventoredProductsCopy = [...notInventoredProducts]
    notInventoredProductsCopy[index]['selected'] = bool
    setNotInventoredProducts(notInventoredProductsCopy)
  }

  function sortBy(key, orderBy) {
    var notInventoredProductsCopy = [...notInventoredProducts]
    if (key === 'balance' && orderBy === 'asc') {
      notInventoredProductsCopy.sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance));
    }
    if (key === 'balance' && orderBy === 'desc') {
      notInventoredProductsCopy.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));
    }
    if (key === 'name' && orderBy === 'asc') {
      notInventoredProductsCopy.sort((a, b) => a.productName.localeCompare(b.productName))
    }
    if (key === 'name' && orderBy === 'desc') {
      notInventoredProductsCopy.sort((a, b) => b.productName.localeCompare(a.productName))
    }

    setNotInventoredProducts(notInventoredProductsCopy)
  }

  async function exportToExcel() {
    var excelHeaders = [[t('NAME_OF_PRODUCT'), t('BARCODE'), t('PRICE'), t('EXPECTED_BALANCE')]]
    for (let i = 0; i < notInventoredProducts.length; i++) {
      var excelItems = []
      excelItems.push(notInventoredProducts[i].productName)
      excelItems.push(notInventoredProducts[i].barcode)
      excelItems.push(notInventoredProducts[i].price)
      excelItems.push(notInventoredProducts[i].balance)
      excelHeaders.push(excelItems)
    }
    const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, t('NOT_INVENTORED') + ".xlsx");
  }

  async function showProductUpdateModal(bool, index) {
    if (bool) {
      var response = await GET(`/services/web/api/product/${data.productList[index]['productId']}`, selectedItem, { loader: true })
      response.index = index
      setSelectedItem(response)
    } else {
      setSelectedItem({})
    }
    setModalUpdateProduct(bool)
  }

  async function changeProduct(e) {
    e.preventDefault()

    var dataCopy = { ...data }
    await PUT('/services/web/api/product', selectedItem, { loader: true })
    dataCopy.productList[selectedItem.index]['productName'] = selectedItem.name
    setData(dataCopy)
    showProductUpdateModal(false)
  }

  function setReduxProducts(data) {
    var dataCopy = { ...data }
    if (reduxTransferData.inventoryCreateData?.productList && reduxTransferData.inventoryCreateData?.productList?.length) {
      dataCopy = reduxTransferData.inventoryCreateData
    }
    setData(dataCopy)
  }

  async function getPoses() {
    const response = await GET('/services/web/api/pos-helper')
    if (response) {
      setPoses(response)
      if (!id) {
        if (reduxSettings.posId && findIdFromArray(response, reduxSettings.posId)) {
          setReduxProducts({ ...data, 'posId': reduxSettings.posId })
          // setData({ ...data, 'posId': reduxSettings.posId })
        } else {
          setReduxProducts({ ...data, 'posId': response[0]['id'] })
          // setData({ ...data, 'posId': response[0]['id'] })
        }
      }

      if (id) {
        const temporaryData = await GET('/services/web/api/inventory/' + id)
        setData(temporaryData)
      }
    }
  }

  async function getCategories() {
    const response = await GET('/services/web/api/product-category-helper')
    var arr = []
    for (let i = 0; i < response.length; i++) {
      response[i]['label'] = ' ' + response[i]['name']
      response[i].child = false;
      if (response[i].parentId !== 0) {
        const index = response.findIndex(x => x.id === response[i].parentId)
        response[i].child = true;
        if (response[index].options && response[index].options.length > 0) {
          response[index].options.push(response[i])
        } else {
          response[index].options = [response[i]]
        }
      } else {
        arr.push(response[i])
      }
    }
    setCategories(arr)
    setCategoriesInitial(response)
  }

  useEffect(() => {
    getPoses()
    getCategories()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    annualActualBalance()
  }, [annual]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search.length > 1) {
      searchProduct(search)
    }
    if (search.length === 0) {
      searchProduct('')
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reduxTransferData?.inventoryCreateData?.productList?.length > 0 && data.productList.length === 0) {
      return;
    }
    dispatch({ type: 'SET_INVENTORY_CREATE_DATA', payload: data })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps	

  const Option = props => {
    return (
      <div style={{
        padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
        fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
      }}>
        <components.Option {...props} />
      </div>
    );
  };

  const Row = ({ index, style }) => {
    const item = notInventoredProducts[index];
    return (
      <div style={style} className="d-flex align-items-center">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`control${index}`}
            onChange={(e) => markAsSelected(e.target.checked, index)}
          />
        </div>
        <label className="form-check-label vertical-center text-truncate" htmlFor={`control${index}`} style={{ width: '300px' }}>
          {index + 1}. {item.productName}
        </label>
        <div className="text-center">{item.barcode}</div>
        <div className="text-center">{item.balance}</div>
        <div className="d-flex justify-content-center">
          <input
            type="number"
            className="auto-width-input me-2"
            autoComplete="off"
            name="actualBalance"
            value={item.actualBalance}
            onChange={(e) => setActualBalanceModal(e, index)}
          />
          <div className="table-action-button table-action-primary-button" onClick={() => addToListNotInventoredProduct(index)}>
            <i className="uil-plus"></i>
          </div>
        </div>
      </div>
    );
  };

  const renderNotInventoredProducts = () => (
    <List
      height={500} // Modal balandligi
      itemCount={notInventoredProducts.length}
      itemSize={40}  //r balandligi
      width={800} // Modal kengligi
    >
      {Row}
    </List>
  );

  return (
    <>
      <div className="page-header d-flex justify-content-between flex-wrap mb-2">
        <div className="d-flex text-truncate">
          <h4 className="vertical-center">
            {t('INVENTORY')}
          </h4>
          <div className="vertical-center mt-1">
            <i className="uil uil-angle-double-right fz-20"></i>
          </div>
          <div className="vertical-center mt-1">
            <h6>{t('CREATE')}</h6>
          </div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => notInventoredList()}>
            {t('NOT_INVENTORED')}
          </button>
        </div>
      </div>

      <form autoComplete="off">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label>{t('POS')}<span className="required-mark">*</span></label>
                  <Select
                    isDisabled={data.productList.length > 0}
                    value={poses.find(option => option.id === data.posId)}
                    options={poses}
                    onChange={(option) => setData({ ...data, 'posId': option.id })}
                    placeholder=""
                    noOptionsMessage={() => t('LIST_IS_EMPTY')}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>{t('INVENTORY')} №</label>
                  <input name="inventoryNumber" className="form-control" value={data.inventoryNumber}
                    onChange={(e) => setData({ ...data, 'inventoryNumber': e.target.value })} />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>{t('NOTE')}</label>
                  <input name="note" className="form-control" value={data.note}
                    onChange={(e) => setData({ ...data, 'note': e.target.value })} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive inventory-table mb-3" ref={scrollRef}>
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>{t('NAME_OF_PRODUCT')}</th>
                    <th className="text-center">{t('BARCODE')}</th>
                    <th className="text-center">{t('UNIT')}</th>
                    <th className="text-center">{t('PRICE')}</th>
                    <th className="text-center">{t('EXPECTED_BALANCE')}</th>
                    <th className="text-center">{t('COUNTED')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.productList.map((item, index) => (
                    <tr key={index}>
                      <td className="custom-truncate" style={{ 'width': '30%' }}>
                        {index + 1}. {item.productName}
                      </td>
                      <td className="text-center">{item.barcode}</td>
                      <td className="text-center">{item.uomName}</td>
                      <td className="text-center text-nowrap">{formatMoney(item.price)}</td>
                      <td className="text-center text-nowrap">{formatMoney(item.balance)}</td>
                      <td className="d-flex justify-content-center">
                        <input
                          type="number"
                          className={"auto-width-input " + (item.actualBalance < 0 && "required-border")}
                          id={index + 1}
                          tabIndex={index + 1}
                          name="actualBalance"
                          value={item.actualBalance}
                          onChange={(e) => handleProductListChange(e, index)} />
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="table-action-button table-action-primary-button"
                            onClick={() => showProductUpdateModal(true, index)}>
                            <i className="uil uil-edit"></i>
                          </div>
                          <div className="table-action-button table-action-danger-button ms-2" onClick={() => deleteProduct(index)}>
                            <i className="uil uil-times"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div></div>
            </div>

            <div className="row">
              <div className="col-md-6 vertical-center">
                <div className="form-group position-relative m-0">
                  <DebounceInput
                    disabled={!data.posId}
                    value={search}
                    tabIndex={data.productList.length + 2}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        setSearch(e.target.value)
                      }
                    }}
                    type="text"
                    className="form-control"
                    placeholder={t('SEARCH') + '...'}
                    debounceTimeout={1000}
                  />

                  {searchData.length > 0 ?
                    <i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch(''); setSearchDataExist(false) }}></i>
                    :
                    <i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
                  }

                  {searchData.length > 0 &&
                    <span className="dropdown-search-menu">
                      <div className="dropdown-menu-list">
                        <span className="dropdown-menu-result">
                          <div className="table-responsive p-3">
                            <table className="table-striped table">
                              <thead>
                                <tr>
                                  <th>{t('NAME_OF_PRODUCT')}</th>
                                  <th>{t('BARCODE')}</th>
                                  <th className="text-center">{t('ARTIKUL')}</th>
                                  <th className="text-center">{t('QUANTITY')}</th>
                                  <th className="text-center">{t('ACTION')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {searchData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.productName}</td>
                                    <td>{item.barcode}</td>
                                    <td>{item.artikul}</td>
                                    <td className="text-center">{item.balance} {item.uomName}</td>
                                    <td className="d-flex justify-content-center" onClick={() => addToList(index)}>
                                      <div className="table-action-button table-action-primary-button">
                                        <i className="uil uil-plus"></i>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </span>
                      </div>
                    </span>
                  }

                  {(searchData.length === 0 && searchDataExist) &&
                    <span className="dropdown-search-menu">
                      <div className="dropdown-menu-list d-flex justify-content-between p-2">
                        <span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
                      </div>
                    </span>
                  }

                </div>
              </div>
            </div>

            <div className="btn-wrapper">
              <Link className="btn btn-warning btn-rounded btn-wide me-2" to="/inventory">
                {t('CANCEL')}
              </Link>
              <button type="button" className="btn btn-success btn-rounded btn-wide me-2"
                disabled={!data.productList.length > 0}
                onClick={() => setModalConfirm(true)}>{t('COMPLETE')}</button>
              <button type="button" className="btn btn-primary btn-rounded btn-wide"
                disabled={!data.productList.length > 0}
                onClick={() => inventoryCompleted()}>{t('SAVE_TO_DRAFT')}</button>
            </div>
          </div>
        </div>

      </form>

     {/* Modal Not Inventored Products */}
     <Modal show={modalNotInventored} animation={false} centered size="lg" backdrop="static" onHide={() => setModalNotInventored(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex">
              <div className="me-4">{t('ANNUAL')}</div>
              <div className="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultValue={false}
                  onChange={(e) => setAnnual(e.target.checked)}
                />
              </div>
              <div className="me-2">{t('SORT_BY')}</div>
              <button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'asc')}>[0-9]</button>
              <button className="btn btn-sm btn-success me-2" onClick={() => sortBy('balance', 'desc')}>[9-0]</button>
              <button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'asc')}>[A-Z]</button>
              <button className="btn btn-sm btn-success me-2" onClick={() => sortBy('name', 'desc')}>[Z-A]</button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive inventory-modal-height">
            {renderNotInventoredProducts()} {/* Virtualizatsiya qilingan jadval */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" tabIndex="-1" onClick={() => exportToExcel()}>EXCEL</button>
          <button className="btn btn-info" tabIndex="-1" onClick={() => addToListMinusBalanceProducts()}>{t('ADD_NEGATIVE_BALANCE')}</button>
          <button className="btn btn-success" tabIndex="-1" onClick={() => addToListSelectedProducts()}>{t('ADD_SELECTED')}</button>
          <button className="btn btn-primary" tabIndex="-1" onClick={() => addToListNotInventoredProducts()}>{t('ADD_ALL')}</button>
        </Modal.Footer>
      </Modal>
      {/* MODAL NOT INVENTORED PRODUCTS */}

      {/* MODAL CONFIRM */}
      <Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('CONFIRM')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-2">
            <div>{t('POS')}</div>
            <div className="fw-700">{findFromArrayById(poses, data.posId)}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100">
            <button className="btn btn-warning w-100 me-2" onClick={() => setModalConfirm(false)}>
              {t('CANCEL')}
            </button>
            <button className="btn btn-primary w-100" onClick={() => inventoryCompleted(true)}>{t('CONFIRM')}</button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* MODAL CONFIRM */}

      {/* MODAL INSTANT UPDATE PRODUCT */}
      <Modal show={modalUpdateProduct} animation={false} centered dialogClassName="update-modal-width"
        backdrop="static" onHide={() => showProductUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('PRODUCT')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={changeProduct} autoComplete="off">
            <div className="form-group">
              <label>{t('NAME_OF_PRODUCT')}<span className="required-mark">*</span></label>
              <input type="text" className="form-control" name="name" autoFocus
                value={selectedItem.name}
                onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
            </div>
            <div className="form-group">
              <label>{t('CATEGORY')}</label>
              <Select
                components={{ Option }}
                classNamePrefix="react-select"
                options={categories}
                value={categoriesInitial.find(option => option.id === selectedItem.categoryId)}
                onChange={(option) => setSelectedItem({ ...selectedItem, 'categoryId': option.id })}
                placeholder=""
                noOptionsMessage={() => t('LIST_IS_EMPTY')}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
              />
            </div>
            <div className="d-flex w-100 mt-3">
              <button type="button" className="btn btn-outline-warning w-100 me-2"
                onClick={() => showProductUpdateModal(false)} tabIndex="-1">{t('CANCEL')}</button>
              <button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* MODAL INSTANT UPDATE PRODUCT */}
    </>
  )
}

export default CreateUpdate

